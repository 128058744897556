import type { StripeElements } from '@power-elements/stripe-elements';
import type { TemplateResult } from 'lit';
import type { KghPaymentResponse } from '../../functions/config.js';

import { LitElement, html } from 'lit';
import { customElement, property, query, queryAll, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import bound from 'bind-decorator';

import { handleAsJson } from '../lib/fetch.js';
import { first } from '../lib/string.js';

import styles from './kgh-donation.css';
import shared from './shared-styles.css';

import { getAppCheckToken } from '../lib/captcha.js';
import { MdOutlinedTextField } from '@material/web/textfield/outlined-text-field.js';

import '@material/web/button/filled-button.js';
import '@material/web/button/outlined-button.js';
import '@material/web/button/text-button.js';
import '@material/web/checkbox/checkbox.js';
import '@material/web/dialog/dialog.js';
import '@material/web/textfield/outlined-text-field.js';
import '@material/web/switch/switch.js';
import '@material/web/icon/icon.js';
import '@power-elements/stripe-elements';
import { MdDialog } from '@material/web/dialog/dialog.js';

@customElement('kgh-donation')
export class KghDonation extends LitElement {
  static readonly styles = [shared, styles];

  private static handleStripeResponse({ error, token }): stripe.Token {
    if (error) throw error;
    else return token;
  }

  @property({ type: Number, reflect: true }) amount: number;

  @property({ reflect: true }) stage: 'amount'|'billing'|'success'|'error' = 'amount';

  @property({ type: Number }) until = 24;

  @property({ type: Boolean }) anonymous = false;

  @property({ type: String }) brand = '';

  @property({ type: String }) email = '';

  @property({ type: String }) hoverPrompt = '';

  @property({ type: String }) last4 = '';

  @property({ type: Boolean, reflect: true }) loading = false;

  @property({ type: String }) message = '';

  @property({ type: String }) name = '';

  @property({ type: String }) phone = '';

  @property({ type: Boolean }) pristine = true;

  @property({ type: Boolean }) submitted = false;

  @property({ attribute: false }) error: Error | null = null;

  @property({ attribute: false }) response: KghPaymentResponse = null;

  @property({ attribute: false }) token: stripe.Token;

  @state() ongoing = true;

  @state() monthly = false;

  @query('#stripe') private stripe: StripeElements;

  @queryAll('md-outlined-text-field') private textFields: NodeListOf<MdOutlinedTextField>;

  #formdata?: FormData;

  get amounts() {
    return Array.from(
      this.querySelectorAll<HTMLDataElement>('data[data-amount][data-prompt]'),
      x => ({ prompt: x.dataset.prompt, amount: parseInt(x.dataset.amount) })
    );
  }

  private get prompt(): string {
    const { amount = null } = this;
    const exactAmount = this.amounts.find(a => a.amount === amount);
    return (
        exactAmount ? exactAmount.prompt
      : amount ? 'Every Dollar Brings More Life to the Community'
      : 'Choose The Amount You Would Like to Donate'
    );
  }

  render(): TemplateResult {
    const {
      amount, anonymous, message,
      ongoing, monthly, until,
      loading, prompt,
      email, name, phone,
    } = this;

    const submitDisabled = (
      loading ||
      !amount ||
      !name ||
      !email ||
      !phone
    );

    return html`
      <form id="form"
            @submit="${(e: Event) => e.preventDefault()}"
            @change="${() => this.#onChange()}">

        <legend id="prompt" aria-live="polite">${this.hoverPrompt.replace('\\', '') || prompt}</legend>

        <ol id="amount">
          ${this.amounts.map(({ amount, prompt }) => {
              const { amount: selected } = this;
              return html`
                <li data-amount="${amount}">
                  <input id="donate-${amount}"
                         class="preset"
                         name="amount"
                         required
                         type="radio"
                         aria-controls="prompt"
                         aria-label="$${amount}"
                         data-prompt="${prompt}"
                         value="${amount}"
                         .checked="${selected === amount}"
                         @click="${this.onSelectAmount}"
                         @focus="${this.onAmountHover}"
                         @blur="${this.onAmountUnhover}"
                         ?disabled="${this.loading}"
                  ></input>
                  <label for="donate-${amount}"
                         data-prompt="${prompt}"
                         @mouseenter="${this.onAmountHover}"
                         @mouseleave="${this.onAmountUnhover}"
                         @focus="${this.onAmountHover}"
                         @blur="${this.onAmountUnhover}"
                  ><span>$${amount}</span></label>
                </li>
              `;
          })}
        </ol>

        <md-outlined-text-field id="other-amount"
                                name="amount"
                                slot="other-amount"
                                type="number"
                                required
                                min="0"
                                step="1"
                                label="${amount ? '' : 'Other '}Amount"
                                supporting-text="Please select or enter a donation amount"
                                ?disabled="${this.loading}"
                                .value="${this.amount ?? ''}">
          <md-icon slot="leading-icon">attach_money</md-icon>
        </md-outlined-text-field>

        <md-filled-button id="show-donate-form"
                          raised
                          ?disabled="${!amount}"
                          @click="${() => this.stage = 'billing'}">
          <span style="position: relative;">Donate Securely</span>
        </md-filled-button>

        <section id="billing" ?loading="${loading}">
          <md-outlined-text-field id="name"
                                  name="name"
                                  required
                                  label="Full Name"
                                  value="${name}"
                                  ?disabled="${this.loading}"
                                  supporting-text="The name that appears on your credit card.">
            <md-icon slot="leading-icon">face</md-icon>
          </md-outlined-text-field>

          <md-outlined-text-field id="email"
                                  name="email"
                                  required
                                  type="email"
                                  label="Email Address"
                                  ?disabled="${loading}"
                                  supporting-text="This is the email address that we will send the receipt to.">
            <md-icon slot="leading-icon">email</md-icon>
          </md-outlined-text-field>

          <md-outlined-text-field id="phone"
                                  name="phone"
                                  type="tel"
                                  label="Phone Number"
                                  supporting-text="This is the phone number associated with the credit card."
                                  ?disabled="${loading}"
                                  required>
            <md-icon slot="leading-icon">local_phone</md-icon>
          </md-outlined-text-field>

          <stripe-elements id="stripe"
                           publishable-key="STRIPE_PUBLISHABLE_KEY"
                           generate="token"
                           icon-style="solid"
                           hide-postal-code
                           ?disabled="${loading}"
                           @change="${() => this.requestUpdate()}">
          ></stripe-elements>

        </section>

        <section id="info">
          <md-outlined-text-field id="message"
                                  name="message"
                                  label="Dedication"
                                  rows="4"
                                  maxlength="180"
                                  charcounter
                                  supporting-text="Optional dedication for your donation."
                                  ?disabled="${loading}"
                                  placeholder="L'ilui nishmas... In honour of..."
          ></md-outlined-text-field>

          <label id="monthly" class="switch">
            Make this a Monthly Donation!
            <md-switch id="monthly-field"
                       name="monthly"
                       ?disabled="${loading}"
            ></md-switch>
          </label>

          <div id="ongoing-fields" ?hidden="${!monthly}">
            <label id="ongoing" class="switch">
              Ongoing
              <md-switch name="ongoing"
                         ?disabled="${loading}"
                         .selected="${ongoing}"
              ></md-switch>
            </label>

            <p>
              <small ?hidden="${!ongoing}">Cancel at anytime by emailing us</small>
            </p>

            <md-outlined-text-field id="until-field"
                                    name="until"
                                    ?disabled="${loading || ongoing}"
                                    ?inert="${ongoing}"
                                    .value="${until}"
                                    type="number"
                                    label="Number of Months"
                                    supporting-text="The monthly donations will end after this many months"
            ></md-outlined-text-field>
          </div>

          <label id="anonymous-field" class="switch">
            Make Donation Anonymously
            <md-switch id="anonymous"
                       name="anonymous"
                       value="anonymous"
                       ?disabled="${loading}"
            ></md-switch>
          </label>

          <article class="alert" id="alert-card">
            <md-icon>info</md-icon>
            <p>
              Kupat Givat HaMivtar has no access to your credit card number.
              It is securely sent to the payment processor and converted to a randomized token.
            </p>
          </article>

          <article class="alert">
            <md-icon title="info">info</md-icon>
            <p>We will not share or sell your contact information. In order to process the transaction, we will record your billing name.</p>
          </article>

          <article class="alert ${classMap({ warn: !anonymous })}">
            <md-icon>info</md-icon>
            <p>We ${anonymous ? 'won\'t' : 'will'} publish your name with the amount of your donation on Twitter.</p>
          </article>

        </section>

        <section id="actions">
          <a href="https://stripe.com">
            <img loading="lazy"
                 alt="Stripe Payment Processor"
                 srcset="/assets/images/stripe.png 1x,
                         /assets/images/stripe@2x.png 2x,
                         /assets/images/stripe@3x.png 3x">
          </a>
          <md-filled-button id="submit"
                            raised
                            icon="credit_card"
                            ?disabled="${loading || submitDisabled}"
                            @click="${this.onSubmit}">
            <span style="position: relative;">Donate Securely</span>
          </md-filled-button>

        </section>

        ${this.#successDiag()}

        ${this.#errorDiag()}
      </form>
    `;
  }

  #successDiag() {
    return html`
        <md-dialog id="success-dialog"
                   type="alert"
                   ?open="${!!this.response}"
                   @closed="${this.reset}">
          <h2 slot="headline">Thank You for Your Donation</h2>
          <form id="success-form"
                slot="content"
                method="dialog">
            <section>${this.responseText()}</section>
            <p>- The Gabbaim</p>
            <div id="signatures">
              <img loading="lazy"
                   src="/assets/images/rygreenblatt.png"
                   alt="Signature of Rabbi Yechiel Greenblatt">
              <img loading="lazy"
                   src="/assets/images/rdflegg.png"
                   alt="Signature of Rabbi Dovid Flegg">
            </div>
          </form>

          <div slot="actions">
            <md-filled-button form="success-form"
                              icon="thumb_up"
                              value="ok">Cool!</md-filled-button>
          </div>
        </md-dialog>
    `;
  }

  #errorDiag() {
    return html`
        <md-dialog id="error-dialog"
                   type="alert"
                   ?open="${this.error}"
                   @close="${this.onErrorDialogClosed}">
          <h2 slot="headline">Oy Vey!</h2>
          <form id="error-form"
                slot="content"
                method="dialog">
            <p>Something went wrong with your donation.</p>
            <p>This is the error message that was received:</p>
            <pre id="error-message"><output>${this.error?.message}</output></pre>
            <p ?hidden="${!(this.error as Error & {charge: string})?.charge}">
              Charge code:
              <code>${(this.error as Error & {charge: string})?.charge}</code>
            </p>
          </form>

          <div slot="actions">
            <md-filled-button id="try-again"
                              icon="refresh"
                              form="error-form"
                              value="try-again">
              Try again
            </md-filled-button>

            <md-outlined-button id="cancel"
                                icon="cancel"
                                form="error-form"
                                value="cancel">
              Start Over
            </md-outlined-button>
          </div>

        </md-dialog>
    `;
  }

  async #onChange() {
    const { monthly, ongoing } = this;
    const form = this.shadowRoot.getElementById('form') as HTMLFormElement;
    await this.updateComplete;
    await Promise.all(Array.from(form.elements, (x: LitElement) => x.updateComplete));
    await Promise.all(Array.from(this.shadowRoot.querySelectorAll('md-switch'), (x: LitElement) =>
      x.updateComplete));
    await this.updateComplete;
    this.#formdata = new FormData(form);
    this.email = this.#formdata.get('email').toString();
    this.phone = this.#formdata.get('phone').toString();
    this.name = this.#formdata.get('name').toString();
    this.message = this.#formdata.get('message').toString();
    this.anonymous = this.#formdata.has('anonymous');
    this.monthly = this.#formdata.has('monthly');
    this.ongoing = this.#formdata.has('ongoing') || !monthly && this.monthly;
    const amount = parseFloat(this.#formdata.get('amount') as string);
    const until = parseFloat(this.#formdata.get('until') as string);
    if (!Number.isNaN(amount))
      this.amount = amount;
    if (this.#formdata.get('until') && !Number.isNaN(until))
      this.until = until;
    if (!ongoing && this.#formdata.has('ongoing') && !this.until)
      this.until = 24;
  }

  private onErrorDialogClosed(event: Event): void {
    if (event.target instanceof MdDialog) {
      switch (event.target.returnValue) {
        case 'try-again': this.onSubmit(); break;
        case 'cancel': this.reset(); break;
      }
    }
  }

  private hoverPromptTimeout: ReturnType<typeof setTimeout>;

  private onAmountHover(event: Event & { target: HTMLElement }): void {
    clearTimeout(this.hoverPromptTimeout);
    this.hoverPrompt = event.target.dataset.prompt;
  }

  private onAmountUnhover(): void {
    this.hoverPromptTimeout = setTimeout(() => this.hoverPrompt = '', 500);
  }

  private onSelectAmount(event: Event & { target: HTMLInputElement }): void {
    this.amount = Number(event.target.value);
  }

  @bound
  private onError(error: Error): void {
    this.error = error;
    this.loading = false;
  }

  @bound
  private onPaymentSuccess(body: KghPaymentResponse): void {
    this.response = body;
    this.loading = false;
    this.dispatchEvent(new CustomEvent('payment-success', { bubbles: true, detail: body }));
    // Google success tracking
    const { amount: value } = this;
    const currency = 'USD';
    try {
      gtag('event', 'purchase', { value, currency });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  @bound
  private async onPaymentResponse(response: Response): Promise<Response> {
    if (response.status !== 200) throw new Error(await response.text());
    else return response;
  }

  private async onSubmit(): Promise<void> {
    if (!this.validate()) return;

    this.error = null;
    this.loading = true;

    try {
      const {
        amount,
        anonymous,
        email,
        message,
        monthly,
        until,
        ongoing,
        name,
        phone,
      } = this;

      this.stripe.value = { email, phone } as any;

      this.stripe.tokenData = { name };

      const { id: token } = await this.stripe.submit()
        .then(KghDonation.handleStripeResponse);

      const method = 'POST';

      const body = JSON.stringify({
        amount,
        anonymous,
        email,
        monthly,
        until: !ongoing ? Number(until) : 0,
        name,
        phone,
        token,
        message,
      });

      const headers = new Headers();
      try {
        const token = await getAppCheckToken();
        headers.set('X-Firebase-AppCheck', token);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Could not get AppCheck token: ${error}`);
      }

      fetch('/payment', { method, body, headers })
        .then(this.onPaymentResponse)
        .then(handleAsJson)
        .then(this.onPaymentSuccess)
        .catch(this.onError);
    } catch (error) {
      this.onError(error);
    }
  }

  private responseText(): string|TemplateResult {
    if (!this.response) return '';
    const { name, brand, last4, receiptUrl, anonymous, monthly, amount } = this.response;
    const type = anonymous ? 'anonymous' : 'public';
    const period = monthly ? 'monthly' : 'one-time';
    const amtString = ((amount ?? 0) / 100).toFixed(2);

    const cardDetails = !brand || !last4 ? '' : html` with the
      <img loading="lazy" id="brand" src="/assets/cards/${brand}.svg" alt="${brand}"> card ending in ${last4}`;

    return html`
      <p class="lead">Dear ${first(name)},</p>

      <p>
        Your ${type}, ${period} donation of $${amtString} was successfully processed${cardDetails}.
      </p>

      <p>
        On behalf of the <em>Aniyei Yerushalayim</em> who will benefit from your donation, we would like to thank you.
        May Hashem merit you in receiving the abundant <em lang="yi">brachos</em> which He bestows upon donors like yourself who endeavor to fulfill the Mitzvah of Tzedakah
        modestly and in a most dignified manner. Please contact us with any questions or comments.
      </p>

      <p ?hidden="${!receiptUrl}">You may <a href="${receiptUrl}" target="_blank" rel="noreferrer noopener">view your receipt online</a>. You will also receive a copy by email.</p>

      <p>With Best Regards,</p>
    `;
  }

  public validate(): boolean {
    return [...this.textFields].map(field => field.reportValidity()).every(Boolean);
  }

  public async reset(): Promise<void> {
    this.amount = null;
    this.anonymous = false;
    this.email = '';
    this.loading = false;
    this.name = '';
    this.phone = '';
    this.error = null;
    this.response = null;
    this.message = '';
    this.stripe.reset();
    await this.updateComplete;
    this.textFields.forEach(field => field.reset());
  }
}
